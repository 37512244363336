
.gloabalModal {
    position: fixed;
    overflow: auto;
    top: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    width: 100vw;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
    z-index: 1111;
  }
  
  .gloabalModal .modal_visible {
    width: 100%;
    max-width: 700px;
    padding: 40px 8px;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 20px;
    position: relative;
  }
  
  .gloabalModal p {
    color: black;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(37, 37, 37);
    font-size: 18px;
    font-weight: 600;
  }